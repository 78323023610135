/** @jsx jsx */
import { jsx, Box, Heading, Text } from 'theme-ui';

const TeamMember = ({ member, ...props }) => {
  return (
    <Box as="article" sx={styles?.member} {...props}>
      <Heading as="h3">{member?.name}</Heading>
      <Text as="p">{member?.bio}</Text>
    </Box>
  );
};

export default TeamMember;

const styles = {
  member: {
    borderBottom: '2px solid #63B9D6',
    marginBottom: 8,
    mx: [null, null, null, 6, 8],
    width: [null, null, null, 'calc(50% - 60px)', 'calc(50% - 80px)'],
    h3: {
      fontWeight: 500,
      fontSize: [2, null, null, 3],
      lineHeight: 1.68,
      letterSpacing: 'heading',
      color: 'text',
      mb: [2, null, null, 3],
    },
    p: {
      color: 'textSecondary',
      fontSize: [1, null, null, 2],
      lineHeight: 2,
      mb: 4,
    },
  },
};
