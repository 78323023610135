/** @jsx jsx */
import { jsx, Container, Heading } from 'theme-ui';
import Masonry from 'react-masonry-component';
import TeamMember from 'components/cards/team-member';

const masonryOptions = { originTop: false };

const Team = () => {
  // Local state
  const teamMembers = [
    {
      "id": 0,
      "name": "Calum Bird",
      "bio": "Calum leads operations and engineering at Trelent. He has nearly a decade of experience in software development, previously running a cloud desktop provider and web development firm in British Columbia."
    },
    {
      "id": 1,
      "name": "Alexandra Chapman",
      "bio": "Alexandra leads marketing at Trelent. She has an extensive background of building highly successful campaigns and strategies for brick & mortar businesses across The Maritimes."
    }
  ];

  teamMembers.sort((a, b) => (a.id > b.id) ? 1 : -1);

  return (
    <section id="who" sx={styles.section}>
      <div style={{marginTop: '5rem'}}></div>
      <Container sx={styles.content}>
        <Heading as="h1" textAlign="left" style={{marginBottom: '3rem'}}>
          <code sx={styles.highlightedWord}>Who</code> are we?
        </Heading>
        <Masonry options={masonryOptions} sx={styles.grid}>
          {teamMembers.map((item) => {
            return <TeamMember key={item.id} member={item} className="team-member" />;
          })}
        </Masonry>
      </Container>
    </section>
  );
};

export default Team;

const styles = {
    highlightedWord: {
        backgroundColor: '#333',
        color: '#63B9D6',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
        fontWeight: 'bold'
    },
    section: {
        pt: [8, null, null, null, 10, 14],
        pb: [null, null, null, null, null, 10, 14],
        minHeight: '40vh'
    },
    grid: {
        alignItems: 'center',
        justifyContent: 'center',
        mx: [null, null, null, -6, -8, null],
    },
    content: {
        textAlign: ['left', null, null, null, null],
        h1: {
            color: 'text',
            fontWeight: 'bold',
            fontSize: [8, null, null, null, 12, 14, 15],
            lineHeight: [1.1, null, null, null, 1.2],
            letterSpacing: 'heading',
        },
        p: {
            color: 'textSecondary',
            fontSize: [2, null, null, 4],
            lineHeight: 1.5,
            maxWidth: 480,
            mx: [null, null, null, 'auto', 'unset'],
            marginTop: [1, null, null, 3],
        },
    },
};
