/** @jsx jsx */
import { jsx, Box, Grid, Container, Heading } from 'theme-ui';

const AboutBanner = () => {

  return (
    <section id="home" sx={styles.section}>
      <Container sx={styles.container}>
        <Grid sx={styles.grid} gap={4}>
          <Box sx={styles.content} style={{marginTop: '5rem', marginBottom: '5rem'}}>
            <Heading as="h1" textAlign="center">
              The <a href="#who" style={{textDecoration: 'none'}}><code sx={styles.highlightedWord}>who</code></a>, <a href="#what" style={{textDecoration: 'none'}}><code sx={styles.highlightedWord}>what</code></a>, and <a href="#why" style={{textDecoration: 'none'}}><code sx={styles.highlightedWord}>why</code></a> behind Trelent.
            </Heading>
          </Box>
        </Grid>
      </Container>
    </section>
  );
};

export default AboutBanner;

const styles = {
  highlightedWord: {
    backgroundColor: '#333',
    color: '#63B9D6',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem'
  },
  section: {
    pt: [8, null, null, null, 10, 0],
    pb: [8, null, null, null, 10, 0],
    minHeight: '40vh'
  },
  container: {
    px: [3, null, null, 6],
  },
  grid: {
    alignItems: ['center'],
    gridTemplateColumns: ['1fr', null, null, null, null],
    minHeight: [null, null, null, null, '60vh', '100vh'],
    pt: [15, null, null, 17, 12],
  },
  content: {
    textAlign: ['center', null, null, null, 'center'],
    h1: {
      color: 'text',
      fontWeight: 'bold',
      fontSize: [8, null, null, null, 12, 14, 15],
      lineHeight: [1.1, null, null, null, 1.2],
      letterSpacing: 'heading',
    },
    h2: {
      color: 'textSecondary',
      fontWeight: 'bold',
      fontSize: [4, null, null, null, 6, 7, 8],
      lineHeight: [1.1, null, null, null, 1.2],
      letterSpacing: 'heading',
    },
    p: {
      color: 'textSecondary',
      fontSize: [2, null, null, 4],
      lineHeight: 1.5,
      maxWidth: 480,
      mx: [null, null, null, 'auto', 'unset'],
      marginTop: [1, null, null, 3],
    },
  },
  buttonGroup: {
    mt: [4, null, null, null, 6],
    button: {
      minHeight: 45,
      marginRight: 5,
      marginTop: [5, null],
      px: ['17px', 4],
      svg: {
        transition: 'margin-left 0.3s ease-in-out 0s',
        pl: '3px',
      },
      ':hover': {
        svg: {
          pl: '5px',
        },
      },
    },
    '.white': {
      boxShadow: '0px 4px 6px rgba(125, 128, 170, 0.08)',
      borderRadius: 5,
      fontWeight: 500,
      ml: 3,
    },
  }
};
