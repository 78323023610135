import React from 'react';
import Seo from 'components/seo';
import Layout from 'components/layout';
import AboutBanner from 'sections/banner-about';
import {default as Who} from 'sections/team';
import {default as What} from 'sections/values';
import {default as Why} from 'sections/mission';

export default function AboutPage() {

  return (
    <Layout>
      <Seo
        title="About"
        description="The who, what, and why about Trelent."
      />
      <AboutBanner />
      <Who />
      <What />
      <Why />
    </Layout>
  );
}
