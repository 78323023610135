/** @jsx jsx */
import { jsx, Box, Grid, Container, Heading, Text } from 'theme-ui';

const Values = () => {
  // Local state
  const teamMembers = [
    {
      "id": 0,
      "name": "Calum Bird",
      "bio": "Calum leads operations and engineering at Trelent. He has a decade of experience in software development, previously running a cloud desktop provider and custom web development firm in British Columbia."
    },
    {
      "id": 1,
      "name": "Alexandra Chapman",
      "bio": "Alexandra leads marketing at Trelent. She has an extensive background of building highly successful campaigns and strategies for brick & mortar businesses across The Maritimes."
    }
  ];

  teamMembers.sort((a, b) => (a.id > b.id) ? 1 : -1);

  return (
    <section id="what" sx={styles.section}>
      <div style={{marginTop: '5rem'}}></div>
      <Container sx={styles.content}>
        <Heading as="h1" textAlign="left" style={{marginBottom: '2.5rem'}}>
          <code sx={styles.highlightedWord}>What</code> do we value?
        </Heading>
        <Grid columns={[1,null, null, 3]} gap={4} style={{minHeight: '75vh', justifyContent: 'center', alignItems: 'center'}}>
          <Box>
            <Heading as="h2" textAlign="center" style={{marginBottom: '2rem'}}>
              Transparency
            </Heading>
            <Text as="p" style={{marginBottom: '3rem'}}>
              We believe in transparency. To this end, we will soon be releasing clear explanations of how we use collected data to improve our product, and how you
              can opt out. We also have a responsibility to leave the world in a better state than we found it. To this end, all ESG (Environmental Social Governance)
              policies will be publicly available on our website as they are implemented.
            </Text>
          </Box>
          <Box>
            <Heading as="h2" textAlign="center" style={{marginBottom: '2rem'}}>
              Innovation
            </Heading>
            <Text as="p" style={{marginBottom: '3rem'}}>
              Our lifeblood is innovation, though we recognize that innovation must not come at the cost of transparency. To the best of our ability, we will publish
              research in the public domain, in the hopes to live up to our mission. Trelent will have been a success once we have had a meaningful impact in increasing
              the accessibility of software development.
            </Text>
          </Box>
          <Box>
            <Heading as="h2" textAlign="center" style={{marginBottom: '2rem'}}>
              Trust
            </Heading>
            <Text as="p" style={{marginBottom: '3rem'}}>
              Trust is vital to any relationship, and this holds true for the relationship between Trelent and our customers. We will be audited for data security
              on a regular basis so that your trust isn't blind - it will be backed by data. Your data is only accessed on a need-to-know basis, and is anonymized
              before use in improving our services.
            </Text>
          </Box>
        </Grid>
      </Container>
    </section>
  );
};

export default Values;

const styles = {
    highlightedWord: {
        backgroundColor: '#333',
        color: '#63B9D6',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
        fontWeight: 'bold'
    },
    section: {
        pt: [8, null, null, null, 10, 14],
        pb: [null, null, null, null, null, 10, 14],
        minHeight: '100vh'
    },
    grid: {
        mx: [null, null, null, -6, -8, 'unset'],
    },
    content: {
        textAlign: ['left', null, null, null, null],
        h1: {
            color: 'text',
            fontWeight: 'bold',
            fontSize: [8, null, null, null, 12, 14, 15],
            lineHeight: [1.1, null, null, null, 1.2],
            letterSpacing: 'heading',
        },
        h2: {
          color: 'textSecondary',
          fontWeight: 'bold',
          fontSize: [4, null, null, null, 6, 7, 8],
          lineHeight: [1.1, null, null, null, 1.2],
          letterSpacing: 'heading',
          textAlign: 'center'
        },
        p: {
            color: 'textSecondary',
            fontSize: [2, null, null, 4],
            lineHeight: 1.5,
            maxWidth: 480,
            mx: [null, null, null, 'auto', 'unset'],
            marginTop: [1, null, null, 3],
        },
    },
};
